.bodyProducts h3{
    font-weight: 700;
    font-size: 18px;
    line-height: 2;
    margin: 24px 16px 10px;
}

.headSide{
    margin-bottom: 20px;
}
.scrollContainer{
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
}
.scrollContainer .hosScroll{
    background: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 8px 15px;
    overflow: hidden;
    overflow-x: auto;
    overflow-anchor: none;
    scrollbar-color: transparent transparent;
    user-select: none;
    white-space: nowrap;

}
.listCategory{
    background-color: #fff;
    padding: 10px 20px;
    border-left: 1px solid #ccc;
    cursor: pointer;
}
.listCategory svg{
    fill: #ae7339;
}
.scrollContainer .hosScroll > a{
    cursor: pointer;
    padding: 8px 20px;
    font-size: 16px;
    color: #000;
    font-weight: 700;
}

.activeScrollLink{
    border-radius: 50px;
    /* background-color: #796b7a; */
    background: var(--main-color);
    color: #fff !important;
    text-decoration: none;
}

.stickyDiv {
    position: -webkit-sticky;
    position: sticky;
    top: 43px;
    /* border-bottom: 1px solid #ccc; */
  }
  @media(min-width:994px){
    .stickyDiv{
        top: 0;
    }
  }
  .modal-backdrop.show {
    opacity: .2 !important;
}
.listOfCategory li{
    border-bottom: 1px solid #ddd;
}
.listOfCategory a{
    text-decoration: none;
    color: #000;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    width: 100%;
    display: block;
    padding: 15px;
}
.ScrollLink{
    text-decoration: none;
}
.bodyProducts_ar{
    direction: rtl;
}
/* .backToTop{
    position: -webkit-sticky;
    position: sticky;
    bottom: 80px;
    left: 1000px;
    border: none;
    outline: none;
    background: #796B7a;
    color: #fff;
    padding: 8px 15px;
    display: none;
}
.backToTop i{
    font-size: 22px;
}
.backToTopActive{
    display: block;
} */