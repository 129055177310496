@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Cairo:wght@300;400;500;600;700;800;900&display=swap');
:root{
    --main-color: linear-gradient(
        40deg,
        hsl(29deg 51% 23%) 0%,
        hsl(29deg 49% 28%) 10%,
        hsl(30deg 48% 34%) 20%,
        hsl(30deg 47% 40%) 30%,
        hsl(30deg 46% 47%) 40%,
        hsl(30deg 52% 53%) 50%,
        hsl(28deg 54% 58%) 60%,
        hsl(27deg 55% 63%) 70%,
        hsl(26deg 56% 68%) 80%,
        hsl(25deg 58% 73%) 90%,
        hsl(24deg 58% 77%) 100%
      );
}
body{
    font-family: 'Cairo', sans-serif !important;
}
div{
    font-family: 'Cairo', sans-serif !important;
}


@import "~react-image-gallery/styles/css/image-gallery.css";

@import "react-image-gallery/styles/css/image-gallery.css";

