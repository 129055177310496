.signUpSection .rightSideH .lng{
    color: #000;
}
.signUpSection .rightSideH .lng:hover{
    color: #ae7339;
}
.signUpSection{
    background: #f0f0f0;
    width: 100%;
    min-height: 100vh;
}
.formContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.formContainer .container{
    background: #fff;
    border: 1px solid #e4e4e4;
    max-width: 500px;
    border-radius: 4px;
}
/* @media(max-width:994px){
    .formContainer .container{
        width: 90%;
    }
} */
.formHeaderS{
    text-align: center;
}
.formHeaderS h2{
    font-size: 24px;
    font-weight: 700;
}
.formHeaderS p{
    font-size: 12px;
}
.existLink{
    font-size: 12px;
}
.containerRe_ar{
    flex-direction: row-reverse;
}