.inputSearch{
    width: 80%;
    position: relative;
    background: #fff;
    padding: 10px;
}
input{
    padding: 8px 20px 8px 40px;
    border: none;
    background: #eee;
    width: 100%;
}
.inputSearch i{
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
    /* right: 0; */
    left: 20px;
    color: #777;
}
.textSearch{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 14px;
}
.linktohome{
    text-decoration: none;
    color: #000;
    font-size: 24px;
    font-weight: 700;
}