.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    padding: 1rem;
    transition: all .3s;
    background-image: linear-gradient(to bottom, #000, transparent);
    z-index: 1;
}

.header_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header_row_ar{
    flex-direction: row-reverse;
}
.leftSide {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}


 .search {
    background-color: #ae7339;
    border-radius: 8px;
    padding: 8px 15px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
}
.search svg{
    fill: #fff !important;
}

.header_logo {
    cursor: pointer;
    position: relative;
}

.header_logo .numCart {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
}

.header_logo svg {
    transition: .3s;
}

/* .header_logo:hover svg {
    fill: rgb(255, 255, 255, .8);
} */

@media(max-width:994px) {

    .header.scrolled {
        border-bottom: 1px solid rgb(0, 0, 0, .2);
        position: fixed;
        background: #fff;
        padding: 0rem 0;
    }

    .scrolled svg {
        fill: #000;
    }

    .scrolled .header_logo .numCart {
        color: #000;
    }
}
.popover {
    left: -50px !important;
}
.popover-arrow{
    left: 50px !important;
}
.pop{
    background-color: #fff;
    min-width: 310px !important;
    min-height: 100px;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.buttons button{
    border: none;
    outline: none;
    background: #abaaac;
}
.viewCartBtn{
    text-decoration: none;
    color: #fff;
    background-color: #ae7339;
    font-size: 16px;
    font-weight: 700;
    border-radius: 6px;
    padding: 8px 20px;
}
.PopCards{
    width: 100%;
}
.PopCardsCard{
    padding: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.PopCardsdetails h4{
    font-size: 16px;
    font-weight: 700;
}
.quantityprice{
    font-size: 16px;
}
.PopCards .PopCardsImg{
    width: 80px;
    height: 80px;
}
.PopCardsImg img{
    max-width: 100%;
    height: 100%;
}
.btnTrash{
    cursor: pointer;
}
.rightSideH{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.lng{
    color: #fff;
    font-size: 22px;
    cursor: pointer;
    transition: all .3s;
}
@media(max-width:994px){
    .lng{
        color: #000;
    }
}
.lng:hover{
    color: #ae7339;
}
.listOfRegister{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}
.listOfRegister h5{
    font-size: 14px;
    font-weight: 700;
    padding: 0 4px;
}
.listOfRegister div{
    display: flex;
    align-items: center;
    gap: 10px;
}
.listOfRegister div span{
    font-size: 12px;
    padding: 0 4px;
}
.createAcountBtn{
    width: 100% ;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.createAcountBtn a{
    display: block;
    width:100%;
    text-align: center;
    text-decoration: none;
    background: var(--main-color);
    color: #fff;
    font-weight: 700;
    padding: 12px 0;
}
.alreadyLogin{
    width: 100%;
    text-align: center;
    font-size: 12px;
}
.alreadyLogin a{
    text-decoration: none;
}
.header_row_ar{
    flex-direction: row-reverse;
}
.subTotal{
    border-top: 1px solid #777;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 700;
    padding: 20px 10px;
}
.popover-body{
    padding: 0 !important;
}