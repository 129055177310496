.common_section{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.common_section .bgImg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.commonImg{
    max-width: 100%;
    object-fit: contain;
}
.carousel {
    height: 100%;
}
.common_section .bgImg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-fit: contain;
    aspect-ratio: 1;
}
.common_section .bgImg::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0,.5);
}
.commonTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.commonTitle img{
    border-radius: 50%;
    width: 100px;
    height: 100px;
    border: 2px solid #fff;
}
.commonTitle h1{
    color: #fff;
    font-weight: 700;
    font-size: 42px;
}
.commonTitle{
    display: none;
}
.common_section .bgImg::after{
    background-color: rgba(0, 0, 0,0);
}
@media(max-width:768px){
    .common_section{
        padding: 130px 0;
    }
    .common_section h1{
        font-size: 1.9rem;
    }
}
