main{
    overflow: hidden;
}
.comon{
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100vh;
}
.comon_ar{
    left: 0;
}
.comonL{
    position: relative;
    padding: 0 0 100px 0 !important;
    min-height: 100vh;
    background-color: #f0f0f0;

}
.comonL .headSide {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* gap: 5px; */
    border-bottom: 1px solid rgba(0, 0, 0,.2);
    width: 100%;
    padding: 10px 15px;
    background-color: #fff;
    margin-bottom: 0px;
}
.comonL .headSide span{
    font-weight: 700;
    font-size: 22px;
}
.comonL .headSide img{
    border-radius: 50%;
    width: 100px;
    height: 100px;
}
.catgs{
    padding: 20px 0;
    background-color: #f0f0f0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(170px,auto));
    gap: 10px;
}

.catg {
    position: relative;
    /* max-width: 100%; */
    /* height: 220px; */
    /* overflow: hidden; */
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 40px;
}
.catg img{
    border-radius: 20px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    max-width: 200px;
    max-height: 250px;
    position: relative;
    object-fit: cover;
}
.catg img::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgb(0, 0, 0,.3);
    /* z-index: -1; */
}
.catg h3{
    color: #000;
    text-align: right;
    padding-right: 8px;
    font-size: 16px;
    font-weight: 700;
    padding-top: 10px;
}
.rw{
    margin-right: 0 !important;
    --bs-gutter-x: 0rem !important;
}
.rw_ar{
    flex-direction: row-reverse;
}
@media(max-width:994px){
    .comon{
        position: relative;
    }
    .row{
        display: flex;
        flex-direction: column-reverse;
    }
    .comon{
        height: 40vh;
    }
    .comonL .headSide span{
        font-weight: 700;
        font-size: 18px;
    }
    .comon_ar{
        right: 0;
    }
    .rw_ar{
        flex-direction: column-reverse;
    }
    .catg h3{
        font-size: 12px;
    }
}
/* @media(max-width:767px){
    .catg h3{
        font-size: 12px;
    }
} */

.cartBtnn{
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    position: fixed;
    bottom: 0;
    width: 33%;
}
@media(max-width:994px){
    .cartBtnn{
        width: 100%;
    }
    .catgs{
        padding: 20px 0;
        background-color: #f0f0f0;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px,auto));
        gap: 10px;
    }
}
.reviewCartBtn{
    /* background: #796b7a; */
    background: var(--main-color);
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    border: none;
    outline: none;
    padding: 10px 15px;
    border-radius: 6px;
    text-decoration: none;
}
.numCartPrice{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.numproductcart {
    width: 25px;
    border-radius: 50%;
    background: #fff;
    color: #000;
    font-size: 18px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
}
.numCartPrice_price{
    font-size: 18px;
}
.ReviewCart{
    font-size: 18px;
    font-weight: 700;
}
.delFees{
    background: #fff;
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
}
.delfeestxt{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    gap: 8px;
}
.delfeestxt h5{
    font-size: 14px;
    margin: 0;
}
.delfeestxt i{
    font-size: 16px;
}
.backToTop{
    /* position: -webkit-sticky; */
    position: fixed;
    bottom: 80px;
    /* left: 100%; */
    right: 10px;
    border: none;
    outline: none;
    /* background: #796B7a; */
    background: var(--main-color);
    color: #fff;
    padding: 8px 15px;
    display: none;
    width: fit-content;
    border-radius: 12px;
}
.backToTop i{
    font-size: 22px;
}
.backToTopActive{
    display: block;
}
@media(min-width:994px){
    .backToTop{
        /* right: 0; */
        left: 10px !important;
        /* right: -500px !important; */
    }
}