.custom-modal .modal-body {
  padding-bottom: 80px !important;
}
.product_swiper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.product_swiper {
  /* width: 550px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.product_swiper .App {
  width: 100%;
  object-fit: contain;
}
.image-gallery {
  min-height: 500px; /* Adjust based on your layout */
  width: 80%; /* Example to make the gallery wider on the page */
  margin: auto; /* Center the gallery if it's not full width */
}
.custom-btn {
  background-color: #ba8045;
  color: white; /* Choose a text color that offers good contrast */
  border-color: #ba8045; /* Optional: if you want the border color to match */
}
.custom-option-label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.custom-btn:hover {
  background-color: #9c6c3c; /* A slightly darker shade for hover */
  color: white;
}
.customOptionInput input::placeholder {
  color: #aaa; /* Faded text color */
  font-size: 0.8em; /* Smaller font size */
}

.content_productDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}
.content_productDetails h2 {
  font-size: 20px;
  font-weight: 700;
  color: #000;
}
.content_productDetails p {
  font-size: 16px;
  color: #606060;
  margin: 0;
}

.counterCart {
  margin: auto;
  width: fit-content;
  padding: 10px 73px;
  display: flex;
  justify-content: center;
  gap: 39px;
  align-items: center;
  border: 1px solid rgb(96, 96, 96, 0.2);
  border-radius: 20px;
}
.counterCart button {
  border: none;
  outline: none;
  background: transparent;
}
.counterCart button svg {
  fill: #a0a0a0;
}
.counterCart span {
  font-size: 24px;
  font-weight: 700;
  color: #000;
}
.buttonToCart {
  text-decoration: none;
  /* background: #796b7a; */
  background: var(--main-color);
  border-radius: 6px;
  font-size: 18px;
  color: #fff;
  margin: 20px 0;
  font-weight: 700;
  padding: 8px 16px;
  border: none;
  outline: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.buttonToCustomOption {
  text-decoration: none;
  background: var(
    --secondary-color
  ); /* Optional: use a different color variable or the same as buttonToCart */
  border-radius: 6px;
  font-size: 16px; /* Smaller font size */
  color: #fff;
  padding: 6px 12px; /* Smaller padding */
  border: none;
  outline: none;
  width: 80%; /* Smaller width relative to buttonToCart */
  margin: 10px auto; /* Centering the button and reducing margin */
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600; /* Slightly less weight if preferred */
  transition: background-color 0.3s ease; /* Smooth transition for hover effects */
}

.buttonToCustomOption:hover {
  background-color: #0056b3; /* A darker shade for hover, ensure contrast */
}
/* Smaller and less prominent button for sub-products */
.subProductButton {
  text-decoration: none;
  background: #c58749; /* Slightly different color to differentiate from main action */
  border-radius: 4px;
  font-size: 16px; /* Smaller font size */
  color: #fff;
  margin: 10px 0;
  font-weight: 600; /* Less bold than the main button */
  padding: 6px 12px; /* Smaller padding */
  border: none;
  outline: none;
  width: 80%; /* Less width to differentiate from main button */
  display: flex;
  justify-content: center;
  align-items: center;
}

.subProductButton:hover {
  background-color: #b07340; /* Slightly darker on hover to provide feedback */
}

.itemPriceAndShareBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shareBtn {
  padding-right: 20px;
}
.shareBtn .share {
  outline: none;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 16px;
}
.sharableIcons {
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  display: none;
  margin-bottom: 100px;
}
.sharableIcons button {
  border: none;
  outline: none;
  background: transparent;
}
.sharableIconsActive {
  display: flex;
}
.titleProduct {
  padding: 15px 0 8px 0;
}
.titleProduct span {
  font-size: 20px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .detailsRow {
    flex-direction: row-reverse;
    /* display: row-reverse !important; */
  }
}
.contactWhats {
  display: flex;
  align-items: center;
  gap: 15px;
}
.contactWhats span {
  font-size: 22px;
  font-weight: 700;
}
.contactWhats a {
  text-decoration: none;
  color: green;
  font-size: 24px;
  /* font-weight: 700; */
}
.contactWhats {
  background: green !important;
}
.contactWhats i {
  font-size: 24px;
}
@media (max-width: 776px) {
  .counterCart {
    padding: 8px 0;
    gap: 25px;
  }
}

.productDetailsIcon {
  position: fixed;
  z-index: 1000;
  top: 15px;
  left: 30px;
  border: none;
  font-size: 20px;
  padding: 8px 20px;
  background: var(--main-color);
  text-decoration: none;
  color: #000;
}
@media (min-width: 992) {
  .productDetailsIcon {
    display: none;
  }
  .product_modal .product_swiper {
    flex: 0 0 66.666667%; /* Adjust to occupy more space */
    max-width: 66.666667%; /* Adjust to occupy more space */
  }
  .product_modal .content_productDetails {
    flex: 0 0 33.333333%; /* Reduce the width accordingly */
    max-width: 33.333333%; /* Reduce the width accordingly */
  }
}
.detailsRow_rtl {
  direction: rtl;
}
.cardsToo {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.cardToo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border: 1px solid #ddd;
  cursor: pointer;
  padding: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.cardToo img {
  width: 50px;
  height: 50px;
}
.cardToo button {
  transition: background-color 0.3s ease;
  margin: 0;
  background-color: #c58749; /* Primary button color for sub-products */
  color: white;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  width: 100%; /* Make the button full width within the card */
}
.cardToo button:hover {
  background-color: #0056b3; /* Darker shade for hover */
}

.quantity-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.quantity-selector button {
  font-size: 1.2rem;
  width: 35px;
  height: 35px;
  line-height: 35px;
  padding: 0;
}

.quantity-selector span {
  margin: 0 10px;
}
.image-gallery .image-gallery-image {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Use 'cover' if you want images to fill the space completely */
}
.image-gallery-fullscreen .image-gallery-image {
  object-fit: contain;
  max-width: 100%;
  max-height: 100vh; /* Use 100% of the viewport height */
  width: auto; /* Scale width automatically to maintain aspect ratio */
  height: auto; /* Scale height automatically to maintain aspect ratio */
}
