.cartSection{
    min-height: 100vh;
}
.cartHeader{
    box-shadow: rgba(65, 65, 65, 0.1) 0px 3px 6px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 10px 0;
    background: #fff;
    z-index: 1;
}
.cartHeader .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* gap: 16px; */
}
.cartHeader .container > a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    text-decoration: none;
}
.cartHeader .container > a > img{
    max-width: 100%;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}
.cartHeader .container > a > h1{
    font-size: 24px;
    color: #000;
    margin: 0 !important;
    padding: 0;
}
.cartHeader .container a:hover h1{
    color: #ae7339;
}
@media(max-width:767px){
    .cartHeader .container > a > h1{
        display: none;
    }
}
.logoCart svg{
    fill: #000;
}
.logoCart:hover svg{
    fill: rgb(0, 0, 0,.7) !important;
}
.logoCart span{
    color: #000 !important;
}
.cartSectionBody{
    background: #f0f0f0;
    min-height: 100vh;
    /* padding: 30px 0; */
}
.delivery h2{
    font-size: 24px;
    font-weight: 700;
}
.deliverySelectTime{
    background: #fff;
    padding: 15px;
    border-radius: 10px;
}
.deliverySelectTime h4{
    font-size: 16px;
    font-weight: 700;
    margin: 0;
}
.modalDateBtn{
    /* background: #796b7a !important; */
    /* border: 2px solid #796b7a !important; */
    border: 2px solid var(--main-color) !important;
    background: var(--main-color) !important;
    outline: none;
    font-size: 20px !important;
}

.deliveryDetails{
    background: #fff;
    margin-top: 20px;
    padding: 15px;
    border-radius: 10px;
}
.deliveryDetails h4{
    font-size: 18px;
    font-weight: 700;
    color: #000;
    /* margin-bottom: 20px; */
}
.selectPlaceType{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 20px 0;
}
.House{
    border: 1px solid #ddd;
    /* padding: 32px 0; */
    border-radius: 10px;
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.House_active{
    border: 1px solid #000;
}
.House_title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.House_title,.House_title i{
    color: #ddd;
}
.House_active .House_title,.House_active .House_title i{
    color: #000;
}
.House_title i{
    font-size: 42px;
}
.react-calendar{
    width: 100% !important;
}
.form-check-input {
    appearance: auto !important;
}
.checkCollapse{
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 6px;
}
.collapseChech{
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding: 20px 0 0 0;
}
.PayWith h2{
    font-size: 24px;
    font-weight: 700;
}
.payWithContainer{
    background: #fff;
    width: 100%;
    /* height: 100px; */
    padding: 15px;
}
.paiwithkinet{
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    cursor: pointer;
    transition: all .3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}
.paiwithkinet:hover{
    box-shadow: 0 4px 3px rgba(0, 0, 0, 0.07), 0 2px 2px rgba(0, 0, 0, 0.06);
}
.knetIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #bcbcbd;
}
.priceKnet{
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 4px;
}
.priceKnet svg{
    width: 20px;
    height: 20px;
}
.react-tel-input .form-control{
    width: 100% !important;
}
.cartSection .rightSideH .lng{
    color: #000;
    transition: all .3s;
}
.cartSection .rightSideH .lng:hover{
    color: #ae7339;
}
.rwCart{
    flex-direction: row-reverse;
}

.selectCountry{
    direction: rtl !important;
}

@media(max-width:994px){
    .rwCart{
        flex-direction: column-reverse;
    }
}
.checkoutcontainer_ar{
    direction: rtl;
}
a.searchIconH{
    text-decoration: none;
    color: #000;
    font-size: 22px;
    /* font-weight: 700; */
}
.timeSelected{
    display: flex;
    gap: 12px;
    align-items: center;
}
.formDeliveryHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.formDeliveryHeader a{
    text-decoration: none;
    background: #eeeeee;
    padding: 10px;
    border-radius: 12px;
    color: #000;
}
.formDeliveryHeader a i{
    font-size: 20px;
}
.formDeliveryHeader a span{
    font-size: 18px;
    font-weight: bold;
}
.selecNow{
    display: flex;
    justify-content: space-between;
}
.selecNow .timesToSelect{
    padding: 0;
    gap: 10px;
}
.selecNow .modalDateBtn{
    font-size: 14px !important;
}
/* .namee{
    border-color: red !important;
}
.namee:focus{
    border-color: transparent !important;
} */





.input-with-label {
    position: relative;
  }
  
  .input-with-label input {
    padding: 10px;
  }
  
  .input-with-label label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    margin: 0 10px;
    background-color: #fff;
  }
  .dirLabel{
    direction: rtl;
  }
  .input-with-label-ar label{
    right: 35px;
  }

  .input-with-label input:focus + label,
.input-with-label input:not(:placeholder-shown) + label {
    display: none;
}
  .input-with-label-ar input:focus + label,
.input-with-label-ar input:not(:placeholder-shown) + label {
    display: none;
}
@media(max-width:767px){
    .popCart{
        position: absolute !important;
        left: 25px !important;
    }
}