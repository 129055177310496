 /* *{
    --webkit-box-sizing: border-box;
    --moz-box-sizing: border-box;
    box-sizing: border-box;
}
html{
    scroll-behavior: smooth;
    direction: rtl;
}  */
.body{
    font-family: "Nunito Sans", sans-serif !important; 
    width: 100%;
    height: 100vh;
background-image: linear-gradient(
  40deg,
  hsl(29deg 51% 23%) 0%,
  hsl(29deg 49% 28%) 10%,
  hsl(30deg 48% 34%) 20%,
  hsl(30deg 47% 40%) 30%,
  hsl(30deg 46% 47%) 40%,
  hsl(30deg 52% 53%) 50%,
  hsl(28deg 54% 58%) 60%,
  hsl(27deg 55% 63%) 70%,
  hsl(26deg 56% 68%) 80%,
  hsl(25deg 58% 73%) 90%,
  hsl(24deg 58% 77%) 100%
);

}
ul{
    list-style: none;
    padding: 0 !important;
    margin: 0 !important;
}
ul li{
    padding: 0;
    margin: 0;
}
.mainn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.main{
    z-index: 2;
}
.main .head h1{
    font-weight: 600;
    color: #fff;
    margin: 0;
    font-family: "Great Vibes", cursive;
    font-size: 42px;
}
.main .head p{
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    margin: 0 0 5px 0 !important;
    font-family: "Great Vibes", cursive;
}
.main .container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.main .container i{
    color: rgb(227, 10, 10);
    font-size: 30px;
}
.main .head img{
    width: 150px;
    height: 150px;
    margin-bottom: -10px;
}
.btnn a{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background-color: #fff;
    font-size: 22px;
    font-weight: bold;
    width: 100%;
    border-radius: 20px;
    padding: 6px 12px;
    color: #271a14;
    margin: 0 auto;
}
.btnn a span{
    flex: 1;
}
.btnn a svg{
    background-color: #000;
    border-radius: 50%;
}
.whats{
    color: #25D366;
}
.btnn img{
    width: 32px;
    height: 32px;
}