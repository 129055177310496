.imgReg{
    border-radius: 50%;
    width: 64px;
    height: 64px;
}
.custom-modal-Register{
    position: fixed !important;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    margin: 0 !important;
    padding: 0;
}
.custom-modal-Register > .modal-content{
    position: fixed !important;
    top: 0;
    /* left: 0; */
    width: 40vw !important; /* 3/4 of viewport width */
    height: 100vh !important;
}
.custom-modal-Register_ar > .modal-content{
    right: 0 !important;
    direction: rtl;
  }
  .custom-modal-Register > .closeIcon{
    position: absolute;
    left: 102%;
    top: 10px;
    background-color: #fff;
    border-radius: 8px;
    padding: 8px 15px;
    cursor: pointer;
    color: #000;
    z-index: 100000000000;
  }
   .custom-modal-Register > .closeIcon i{
    font-size: 24px;
  }
   .custom-modal-Register > .modal-body{
    margin: 0 !important;
    padding: 0 !important;
  }

  @media(max-width:767px){
    .custom-modal-Register > .modal-content{
        width: 100vw !important;
    }
    .custom-modal-Register > .closeIcon{
        left: 0;
        border-radius: 50%;
        /* right: 10px !important; */
    }
  }
  .custom-modal-Register > .modal-body{
    overflow-y: auto;
  }
  .modal-header .btn-close{
    margin: 0 !important;
  }