.textAreaModal{
    width: 100%;
    height: 200px;
    padding: 15px 20px;
    outline: none;
    border-radius: 20px;
    border: 1px solid #ddd;
    resize: none;
    
}
.textAreaModal p{
    margin-bottom: 5vw;
}
.textAreaModal:focus{
    border: 1px solid blue;
    /* border: 1px solid #fff; */
}
.specialRequest{
    border: 1px solid #ccc;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px 0;
}
.specialRequestQuestion{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
}
.specialRequestQuestion i{
    font-size: 30px;
}
.AddNote{
    cursor: pointer;
    font-size: 20px;
    color: #ae7339;
}
@media(max-width:767px){
    .specialRequestQuestion{
        font-size: 14px;
    }
    .specialRequestQuestion i{
        font-size: 20px;
    }
    .AddNote{
        font-size: 16px;
    }
}