.Cart_section .headCartSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.headCartSection h2{
    font-size: 24px;
    font-weight: 700;
    color: #000;
}
.headCartSection a{
    color: #ae7339;
    font-size: 16px;
    font-weight: 700;
}
.headCartSection svg{
    width: 14px;
    height: 14px;
}
.productsCart{
    /* margin-top: 20px; */
    background: #fff;
    border-radius: 10px;
    padding: 15px;
}
.productsCart_cards{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    gap: 15px;
}
.productsCart_cards_card{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    border-bottom: 1px solid #ddd;
    padding: 0 0 20px 0;
}
.cartImg{
    width: 120px;
    height: 120px;
}
.cartImg img{
    max-width: 100%;
    height: 100%;
    /* object-fit: contain; */
}
.cardText h4{
    font-size: 14px;
    font-weight: 700;
    color: #000;
    margin: 0;
    padding: 0;
}
.cardText > p{
    font-size: 16px !important;
    margin: 0 !important;
    padding: 0 !important;
    margin-top: 6px !important;
    color: #000 !important;
}
.countity{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    margin-top: 6px;
}
.countity > .counterCart{
    justify-content: center;
    gap: 10px;
    padding: 5px 0;
    border-radius: 4px;
    margin: 0;
}
.countity > .counterCart >span{
    font-size: 18px !important;
}
.removeFrom{
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.totallyPrices{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px 0;
    gap: 15px;
    border-bottom: 1px solid #ddd;
}
.cartTotal{
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    width: 100%;
}
.total{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    color: #000;
    padding: 15px 0 0 0;
}
@media(max-width:767px){
    .cardText{
        text-align: center;
    }
    .productsCart_cards_card{
        gap: 10px;
    }
}
.applyCoupon{
    margin: 20px 0;
    background: #fff;
    cursor: pointer;
}
.applyCopon{
    display: flex;
    justify-content: space-between;
    align-items: center;
}