.modalCalendar > .modal-body{
    margin: auto;
}
.calendarr{
    width: 95%;
}
.selectDeliveryTime >h2{
    font-size: 18px;
    font-weight: 700;
}
.timesToSelect{
    padding: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}
.selectTimeCard{
    background: #f4f4f4;
    border: 1px solid #eaebee;
    border-radius: 6px;
    padding: 12px 0;
    cursor: pointer;
    font-size: 16px;
    flex-basis: 45%;
    text-align: center;
}
.selectedTimeActive{
    background: #ae7339;
    color: #fff;
}
.react-calendar__tile--active{
    background: #ae7339 !important;
    border-radius: 20px;
    padding: 0;
}
@media(max-width:767px){
    .selectTimeCard{
        flex-basis: 100%;
    }  
}