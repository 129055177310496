.productCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.cardContent h4 {
  font-size: 16px;
  font-weight: bold;
}
.cardContent p {
  color: #606060;
  font-size: 14px;
  margin: 8px 0 0;
  line-height: 2;
}
.cardContent span {
  font-weight: bold;
  font-size: 16px;
  /* margin: 0 6px; */
}
.cardContent svg {
  /* color: #796b7a; */
  color: #ae7339;
}
.cardImg > img {
  width: 150px;
  height: auto;
  object-fit: cover;
  border-radius: 20px;
}
