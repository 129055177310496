.product-images-slider .swiper-slide {
  /* padding-top: 100%; */
  overflow: hidden;
  position: relative;
  /* background-color: #fff; */
  border-radius: 20px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}
.product-images-slider .swiper-slide-active {
  transform: scale(1.1);
  z-index: 1;
}

.product-images-slider .swiper-slide-next,
.product-images-slider .swiper-slide-prev {
  transform: scale(0.9) !important;
  z-index: 0 !important;
}
.product-images-slider img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  aspect-ratio: 1/1;
  padding: 15px 10px;
}
.product-images-slider-thumbs .swiper-slide {
  cursor: pointer;
  border: 1px solid transparent;
  padding: 5px;
  background-color: #fff;
  border-radius: 10px;
  width: 50px !important;
  margin: 0 auto !important;
}
.product-images-slider-thumbs .swiper-slide-thumb-active {
  border-color: #2a6ef4;
  box-shadow: none;
  width: 55px !important;
}

.product-images-slider-thumbs-wrapper {
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  position: relative;
}
.product-images-slider-thumbs-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: contain;
  aspect-ratio: 1/1;
  box-shadow: 0px 0px 10px 0px #00000040 !important;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: auto !important;
}
.image-gallery .image-gallery-image {
  max-width: 100%; /* Ensure images are not stretched beyond their natural width */
  height: auto; /* Adjust height automatically to maintain aspect ratio */
}
.image-gallery-fullscreen .image-gallery-image {
  object-fit: contain;
  max-width: 100%;
  max-height: 100vh; /* Use 100% of the viewport height */
  width: auto; /* Scale width automatically to maintain aspect ratio */
  height: auto; /* Scale height automatically to maintain aspect ratio */
}

.image-gallery-slide-wrapper.image-gallery-thumbnails-bottom
  .image-gallery-slide {
  display: flex; /* Use flexbox to center the image vertically */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: auto; /* Set a fixed height for the slide area */
}

.image-gallery-thumbnail-image {
  width: auto; /* Adjust width automatically */
  max-height: 100px; /* Limit thumbnail height */
}
