.custom-modal {
    position: fixed !important;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    margin: 0 !important;
    padding: 0;
}
.custom-modal > .modal-content{
    position: fixed !important;
    top: 0;
    right: 0;
    width: 70vw !important; /* 3/4 of viewport width */
    height: 100vh !important;
  }
  
    .closeIcon{
      position: absolute;
      left: -70px;
      top: 10px;
      background-color: #fff;
      border-radius: 8px;
      padding: 8px 15px;
      cursor: pointer;
      color: #000;
      z-index: 100000000000;
    }
    .closeIcon i{
      font-size: 24px;
    }
    .custom-modal > .modal-body{
      margin: 0 !important;
    }
  @media(max-width:767px){
    .custom-modal .modal-content{
        width: 100vw !important;
    }
    .closeIcon{
        left: 85%;
        border-radius: 50%;
        /* right: 10px !important; */
    }
  }
  .modal-body{
    overflow-y: auto;
  }